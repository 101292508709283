article.login {
    display: block;
    box-shadow: #888 1px 1px 5px;
    border-radius: 5px;
    width: 400px;
    margin: 50px auto;
    padding: 15px;
    background: rgba(0,255,0,.01);
}

article.login > div.header{
    font-size:18px;
    font-weight: bolder;
    border-bottom: solid 1px rgba(0,0,0,.1);
    padding: 10px;
    margin-bottom: 10px;
    text-align: center;
}


article.login div > label{
    width: 100%;
    display: block;
    padding:  5px 5px;
    color:#888;
    font-size: 11px;
}

article.login div > button{
    width: 100%;
    margin: 5px 0;
}

article.login div > input {
    width: 100%;
    display: block;
    direction: ltr;
}

article.login div > div.p-password {
    width: 100%;
    display: block;
    direction: ltr;
}

article.login p {
    font-size: 13px;
}

div.p-inputotp {
    direction: ltr;
}

div.errors {
    background-color: #ff00001a;
    color: #ff0000;
    padding: 2px 10px;
    border-radius: 5px;
}

/*#region responsive */

/* Monitor */
@media (min-width: 768px) and (max-width: 992px) {}

/* Tablet */
@media (min-width: 576px) and (max-width: 768px) {}

/* Phone */
@media (max-width: 576px) {
    article.login {
        width: 100%;
    }

}

/*#endregion */