div.form {
	display: grid;
    grid-template-columns: auto;
	justify-content: center;
	gap: 10px;
	width: 100%;
	margin: 0 auto;
	background-color: #fff;
	border-radius: 5px;
	padding: 10px;
	box-shadow: #888 1px 1px 5px;
}

div.form > div {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 5px;
}

div.form > .full {
    display: block;
}

div.form > .buttons {
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    gap: 5px;
}

div.form > .buttons > button{
width: auto;
}

div.form label {
	width: 100%;
	display: block;
	padding: 5px 5px;
	color: #888;
	font-size: 11px;
}

div.form button {
	width: 100%;
	margin: 5px 0;
}

div.form input {
	width: 100%;
	display: block;
	border: solid 1px #888;
}

campaigns div.form .p-dropdown {
	border: solid 1px #888;
}

campaigns div.form input[required],
#crmStateId,
#crmCityId {
	border: solid 1px red;
	border-width: 1px 4px 1px 1px;
}

campaigns div.form input.ltr {
	direction: ltr;
}

.p-calendar {
	width: 100%;
}

.p-togglebutton {
	width: 100%;
}

.picker-input-wrapper {
	height: auto;
	font-family: auto;
}

.rmdp-container {
	width: 100%;
	height: 35px;
	display: block;
}

.rmdp-container button {
	width: auto !important;
}

.rmdp-container > input {
	font-size: 16px;
	font-family: IranSans;
	width: 100%;
	padding: 10px;
	height: 50px;
}


/*#region responsive */

/* Monitor */
@media (min-width: 768px) and (max-width: 992px) {
}

/* Tablet */
@media (min-width: 576px) and (max-width: 768px) {
}

/* Phone */
@media (max-width: 576px) {
	div.form > div {
		grid-template-columns: 1fr;
	}
}

/*#endregion */
