div.p-carousel-items-container {
  direction: ltr;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  transform: rotate(180deg);
}

.p-carousel-content {
  background: #f5f5f2;
}

div.banner {
  background: #f5f5f2;
}

div.banner > img {
  width: 100%;
}

ul.member-types {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  list-style-type: none;
  margin: 25px 0;
  padding: 0;
  gap: 25px;
}

ul.member-types > li {
  border: solid 1px #888;
  background: #f0f0f0;
  box-shadow: #888 1px 1px 3px;
  border-radius: 8px;
}

ul.member-types > li > a {
  display: grid;
  grid-template-rows: auto max-content max-content; 
}

ul.member-types > li > a > img {
  border-radius: 8px 8px 0 0;
  border: solid 1px #888;
  display: block;
  width: 100%;
  border: 0;
}

ul.member-types > li > a > h2 {
  text-align: center;
  font-size: 18px;
}

ul.member-types > li > a > p {
  text-align: center;
  font-size: 12px;
  color: #888;
}

div.quick-access {
  margin: 15px 0;
  padding: 15px;
}

div.quick-access > h2 {
  text-align: center;
  font-size: 20px;
  color: #888;
}

div.quick-access > ul {
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 10px;
}

div.quick-access > ul > li {
  display: block;
  text-align: center;
}

div.quick-access > ul > li > a {
  text-decoration: none;
  color: #000;
  display: grid;
  grid-template-columns: max-content auto;
  grid-template-rows: auto auto;
}

div.quick-access > ul > li > a > i {
  grid-row: span 2;
  font-size: 50px;
  padding: 5px;
  align-self: center;
  font-weight: normal;
  color: #ffae00;
}

div.quick-access > ul > li > a > span {
  text-align: right;
  padding: 0 5px;
}

div.quick-access > ul > li > a > p {
  color: #888;
  font-size: 10px;
  text-align: right;
  padding: 0 5px;
}

table.sport {
  width: 100%;
  empty-cells: hide;
  border-collapse: collapse;
}

table.sport th {
  border: solid 1px #888888;
  text-align: center;
  background-color: #5edbf1;
  color: #555353;
}
table.sport td {
  border: solid 1px #888;
  text-align: center;
}

table.sport > tbody > tr:nth-child(2n + 1) > td {
  background-color: #bbbab75b;
}

ul.sections {
  padding: 0;
  margin: 15px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  list-style-type: none;
  gap: 25px;
}
ul.sections > li {
  border: solid 1px #888;
  border-radius: 5px;
  box-shadow: #888 1px 1px 5px;
}
ul.sections > li > a {
  display: block;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 40px;
  text-decoration: none;
}
ul.sections > li > a > img {
  object-fit: cover;
  width: 100%;
  height: 300px;
}
ul.sections > li > a > span {
  text-align: center;
  padding:10px;
}

/* #region stores */

div.stores {
  margin: 15px 0;
  padding: 15px;
}

div.stores > h2 {
  text-align: center;
  font-size: 20px;
  color: #888;
}

div.stores > ul {
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 25px;
}

div.stores > ul > li {
  display: block;
  text-align: center;
  box-shadow: #888 1px 1px 5px;
  border-radius: 8px;
}

div.stores > ul > li > a {
  text-decoration: none;
  color: #000;
  display: block;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 40px;
}

div.stores > ul > li > a > img {
  border-radius: 8px 8px 0 0;
  width: 100%;
}

div.stores > ul > li > a > span {
  text-decoration: none;
  color: #000;
}

div.sport-centers > a > img {
  width: 100%;
  border-radius: 7px;
  box-shadow: #888 1px 1px 4px;
}

/*#endregion */

/*#region responsive */

/* Monitor */
@media (min-width: 768px) and (max-width: 992px) {
}

/* Tablet */
@media (min-width: 576px) and (max-width: 768px) {
}

/* Phone */
@media (max-width: 576px) {
  .quick-access > h2 {
    font-size: 15px !important;
  }

  div.quick-access > ul {
    grid-template-columns: auto;
  }

  div.quick-access > ul > li {
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  }

  ul.member-types {
    grid-template-columns: auto;
    gap: 10px;
  }

  ul.member-types > li {
    grid-template-rows: auto;
    position: relative;
    height: 180px;
  }

  ul.member-types > li >a > img {
    width: 50%;
    position: absolute;
    right: 0;
    top: 0px;
  }

  ul.member-types > li > a >  h2 {
    width: 50%;
    left: 0px;
    position: absolute;
    text-align: center;
    top: 25px;
  }

  ul.member-types > li > a>  p {
    width: 50%;
    left: 0px;
    position: absolute;
    text-align: center;
    top: 65px;
  }

  div.stores > ul {
    grid-template-columns: repeat(1, auto);
    gap: 15px;
  }

  ul.sections {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }

}

/*#endregion */
