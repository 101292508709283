/* #region  article.admin */
article.users {
	width: 100%;
	margin: 15px auto;
	border-radius: 5px;
	padding: 10px;
	display: grid;
	gap: 15px;
	overflow-x: scroll;
}
article.users table{
	border-collapse: collapse;

}
article.users table th
{
	background-color: #232222db;
	border: solid 1px #888;
	padding: 5px;
	margin: 0;
	color: #fff;
}

article.users table td
{
	border-bottom: solid 1px #888;
	padding: 5px;
	text-wrap: nowrap;
}

.p-paginator {
	direction: ltr;
}
  
/* #endregion */

/*#region responsive */

/* Monitor */
@media (min-width: 768px) and (max-width: 992px) {
	 
}

/* Tablet */
@media (min-width: 576px) and (max-width: 768px) {
 
}

/* Phone */
@media (max-width: 576px) {
	article.admin {
		width: 100%;
		padding: 5px;
		gap: 10px;
	}
}

/*#endregion */
