/* #region  article.admin */
article.admin {
	width: 40%;
	margin: 15px auto;
	border-radius: 5px;
	padding: 10px;
	display: grid;
	gap: 15px;
}
 
article.admin > div {
	border: solid 1px rgb(0, 165, 80);
	padding: 5px;
	border-radius: 5px;
	box-shadow: rgb(0, 165, 80) 1px 1px 5px;
	display: grid;
	gap: 10px;
}

article.admin > div.summary > h2 {
	text-align: center;
	padding: 0;
	margin: 0;
	border-bottom: solid 1px #888888b5;
}

article.admin > div.summary > ul {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	list-style-type: none;
	align-content: space-around;
	gap: 25px;
	padding: 0;
	margin: 0;
}

article.admin > div.summary > ul > li {
	display: grid;
	text-align: center;
}

article.admin > div.summary > ul > li > strong {
	font-size: 32px;
	color: rgb(0, 70, 4);
}

article.admin > div.summary > ul > li > span {
	font-size: 14px;
}

article.admin > div.summary > div.buttons {
	display: grid;
	gap: 15px;
	justify-content: center;
	grid-template-columns: max-content max-content;
	margin-top: 10px;
}

article.admin > div.summary > div.buttons > a {
	border: solid 1px rgb(0, 165, 80);
	padding: 10px;
	border-radius: 5px;
	background-color: rgb(0, 165, 80);
	box-shadow: #888 1px 1px 5px;
	color: #fff;
}

/* #endregion */

/*#region responsive */

/* Monitor */
@media (min-width: 768px) and (max-width: 992px) {
	article.admin {
		width: 60%;
	}
}

/* Tablet */
@media (min-width: 576px) and (max-width: 768px) {
	article.admin {
		width: 80%;
	}
}

/* Phone */
@media (max-width: 576px) {
	article.admin {
		width: 100%;
		padding: 5px;
		gap: 10px;
	}
}

/*#endregion */
