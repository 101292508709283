/* #region  article.admin */
article.levels {
	width: 100%;
	margin: 15px auto;
	border-radius: 5px;
	padding: 10px;
	display: grid;
	gap: 15px;
	overflow-x: scroll;
}
article.levels table {
	border-collapse: collapse;
}
article.levels table th {
	background-color: #232222db;
	border: solid 1px #888;
	padding: 5px;
	margin: 0;
	color: #fff;
}

article.levels table td {
	border-bottom: solid 1px #888;
	padding: 5px;
	text-wrap: nowrap;
}

article.levels table tr[data-status="Draft"] td {
	background-color: #0000ff25;
}
article.levels table tr[data-status="Published"] td {
	background-color: #00ff0025;
}
article.levels table tr[data-status="Expired"] td {
	background-color: #ff000025;
}

.p-paginator {
	direction: ltr;
}

article.levels div.view-details {
	width: 100%;
	margin: 5px auto;
	border-radius: 5px;
	padding: 20px;
	border: solid 1px #888;
	background-color: #fff;
}

article.levels div.view-details > h2 {
	width: 100%;
	font-size: 16px;
	border-bottom: solid 1px #888;
	padding: 5px;
	margin: 0;
}

article.levels div.view-details ul {
	list-style-type: none;
}

article.levels div.view-details ul > li > label::after {
	content: " : ";
}

article.levels div.view-details > div.buttons {
	display: grid;
	grid-template-columns: max-content max-content max-content;
	gap: 3px;
}

article.levels ul.status {
	list-style-type: none;
	display: grid;
	grid-template-columns: repeat(4, max-content);
	padding: 0;
	margin: 0;
}
article.levels ul.status > li {
	padding: 10px;
	border-bottom: solid 3px transparent;
	cursor: pointer;
}
article.levels ul.status > li.draft {
	background-color: #0000ff25;
}
article.levels ul.status > li.accept {
	background-color: #00ff0025;
}
article.levels ul.status > li.reject {
	background-color: #ff000025;
}
article.levels ul.status > li.active {
	border-bottom: solid 3px rgb(84, 186, 40);
}

div.plate-data {
	display: inline-block;
	width: 300px;
	transform: scale(0.7);
}

article.levels ul.images {
	list-style-type: none;
	width: 100%;
	padding: 0;
	margin: 0;
	display: grid;
	grid-template-columns: repeat(5, auto);
	gap: 5px;
}

article.levels ul.images > li {
	display: inline-block;
	width: 100px;
	height: 100px;
	overflow: hidden;
	border: solid 1px #888;
	border-radius: 5px;
	text-align: center;
	align-content: center;
	position: relative;
}

article.levels ul.images > li > img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

article.levels ul.images > li > a {
	position: absolute;
	top: 5px;
	left: 5px;
	color: red;
	cursor: pointer;
}

article.levels ul.images > li > i {
	font-size: 65px;
	cursor: pointer;
}

#frm_fileImage {
	display: none;
}

.p-editor-toolbar {
	direction: ltr;
}
/* #endregion */

/*#region responsive */

/* Monitor */
@media (min-width: 768px) and (max-width: 992px) {
}

/* Tablet */
@media (min-width: 576px) and (max-width: 768px) {
}

/* Phone */
@media (max-width: 576px) {
	article.levels div.grid {
		width: 100%;
		padding: 5px;
		gap: 10px;
	}
}

/*#endregion */
