/* #region ul.stores */
ul.stores {
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  list-style-type: none;
  gap: 15px;
}

ul.stores > li {
  border: solid 1px #888;
  border-radius: 5px;
  overflow: hidden;
  display: grid;
  grid-template-rows: 160px 40px auto;
  box-shadow: #888 1px 1px 3px;
  gap: 0;
}

ul.stores > li > img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

ul.stores > li > div {
  margin: 0;
  display: grid;
  grid-template-columns: auto 80px;
}

ul.stores > li > div > h2 {
  font-size: 13px;
  margin: 0;
  align-content: center;
  border-left: dashed 1px #888;
  text-align: center;
}

ul.stores > li > div > p {
  font-size: 15px;
  margin: 0;
  align-content: center;
  text-align: center;
  direction: ltr;
}

ul.stores > li > a {
  border-top: dashed 1px #888;
  padding: 10px;
  font-size: 12px;
  text-decoration: none;
}

/* #endregion */

/*#region responsive */

/* Monitor */
@media (min-width: 768px) and (max-width: 992px) {
}

/* Tablet */
@media (min-width: 576px) and (max-width: 768px) {
  ul.stores {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}

/* Phone */
@media (max-width: 576px) {
  ul.stores {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
}

/*#endregion */
