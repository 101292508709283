article.payment {
    display: block;
    border-radius: 5px;
    width: 500px;
    margin: 50px auto;
    padding: 15px;
    background: rgba(0,255,0,.01);
}

article.payment > div.header{
    font-size:18px;
    font-weight: bolder;
    border-bottom: solid 1px rgba(0,0,0,.1);
    padding: 10px;
    margin-bottom: 10px;
    text-align: center;
}

article.payment div > label{
    width: 100%;
    display: block;
    padding:  0 5px;
    color:#888;
    font-size: 11px;
}
article.payment div > span{
    width: 100%;
    display: block;
    padding: 5px;
    margin: 5px 0 ;
    border:solid 1px #ccc;
    border-radius: 8px;
    background: #2c2b69;
    color:#fff;
}

article.payment div > button{
    width: 100%;
}
 
div.text-danger{
    color: #ff0000;
}

div.invoice{
    margin: 0;
    padding: 0;
}

div.invoice > ul{ 
    background: #d3f4f5;
    margin: 0;
    padding: 0;
    border-bottom: solid 1px rgba(255,255,255,.5);
    border-radius: 8px;
    box-shadow: #888 1px 1px 5px;
}

div.invoice > ul > li{ 
    list-style-type: none;
    padding: 5px;
    display: grid;
    grid-template-columns: 150px auto;
    border-bottom: solid 1px rgba(255,255,255,.5);
    position: relative;
}

div.invoice > ul > li > label{
    color: #747474;
}

div.invoice > ul > li > label::after{
    content: " : ";
}

div.invoice > ul > li > span{
    color: #000;
}

table td{
    position: relative;
}

div.invoice > ul > li > b, table td > b{
    position: absolute;
    font-size: 9px;
    top: 8px;
    left: 15px;
    background: red;
    color: #fff;
    padding: 3px;
    transform: rotate(-15deg);
    border-radius: 0 8px 0 8px;
    opacity: .4;
}


div.invoice > table{
    width: 100%;
    margin: 25px 0 ;
    border-collapse: collapse;
    border-radius: 8px;
    box-shadow: #888 1px 1px 5px;
    
}

div.invoice > table > thead > tr>  th{ 
    background: #d3f4f5;
    color: #666666;
}

 
div.invoice th,div.invoice td{ 
    border-bottom: solid 1px #06b6d4;
    
}

div.invoice > table > thead > tr>  th:first-child{ 
    width: 30px ;

}

div.invoice > table > tbody > tr>  td:first-child{ 
    background: #d3f4f5;
    width: 30px;
    text-align: center;
}

div.invoice > table > tfoot > tr>  td{ 
    background: #d3f4f5;
    color: #000;
    font-weight: bold;
}

/*#region responsive */

/* Monitor */
@media (min-width: 768px) and (max-width: 992px) {}

/* Tablet */
@media (min-width: 576px) and (max-width: 768px) {}

/* Phone */
@media (max-width: 576px) {
    article.payment {
        width: 100%;
        margin : 10px auto;
    }

    article.payment table > thead > tr {
        display: grid;
        display: none;
    }

    article.payment table > tbody > tr {
        display: grid;
    }

    article.payment table > tfoot > tr {
        display: grid;
    }

    article.payment table > tbody > tr > td  {
        border-bottom-width: 0px;
    }

    article.payment table > tbody > tr > td:nth-child(2) {
        background: #dfdede;
        padding: 5px;
    }

    article.payment table > tbody > tr > td:last-child {
        text-align: left;
    }

    article.payment table > tbody > tr > td:first-child {
        display: none;
    }

    
    article.payment table > tfoot > tr > td:last-child {
        text-align: left;
    }


}

/*#endregion */
