h2.head {
  text-align: center;
  font-size: 18px;
  border-bottom: solid 1px #88888862;
  padding-bottom: 10px;
}

/* #region ul.location-cities */
ul.location-cities {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  list-style-type: none;
  text-align: center;
  padding: 0;
  margin: 0;
}

ul.location-cities > li {
  border: solid 1px #888;
  border-radius: 5px;
  box-shadow: #888 1px 1px 5px;
}

ul.location-cities > li > a {
  display: grid;
  grid-template-rows: auto;
  text-decoration: none;
  position: relative;
  overflow: hidden;
}

ul.location-cities > li > a > img {
  border-radius: 5px;
  width: 100%;
  transition: all ease-in 0.2s 0.01s;
}

ul.location-cities > li > a:hover > img {
  transform: scale(1.2);
}

ul.location-cities > li > a > span {
  border-radius: 0 0 5px 5px;
  text-align: center;
  background: #00000099;
  color: #fff;
  padding: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
/* #endregion */

/* #region ul.location-rooms */
ul.location-rooms {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  list-style-type: none;
  text-align: center;
  padding: 0;
  margin: 0;
}

ul.location-rooms > li {
  border: solid 1px #888;
  border-radius: 5px;
  box-shadow: #888 1px 1px 5px;
}

ul.location-rooms > li > a {
  display: grid;
  grid-template-rows: auto;
  text-decoration: none;
  position: relative;
  overflow: hidden;
}

ul.location-rooms > li > a > img {
  border-radius: 5px;
  width: 100%;
  transition: all ease-in 0.2s 0.01s;
}

ul.location-rooms > li > a:hover > img {
  transform: scale(1.2);
}

ul.location-rooms > li > a > span {
  border-radius: 0 0 5px 5px;
  text-align: center;
  background: #00000099;
  color: #fff;
  padding: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
/* #endregion */

/* #region ol.room-details*/
ol.room-details{
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 25px;
}

ol.room-details > li{
  border: solid 1px #888;
  display: grid;
  grid-template-columns: max-content auto;
  gap: 15px;
  border-radius: 5px;
}

ol.room-details > li > label{
  padding: 15px;
  background: #74727299;
}

ol.room-details > li > span{
  padding: 15px;
  
}

/* #endregion */

/* #region div.room-image */
div.room-image{
  position: relative;
  height: 450px;
}

div.room-image > img
{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

div.room-image > span{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background-color: #00000099;
  padding: 10px;
  color: #fff;
}

/* #endregion */

div.buttons{
  margin: 15px 0;
}

div.buttons > button{
  margin: 0 10px;
}



/*#region responsive */

/* Monitor */
@media (min-width: 768px) and (max-width: 992px) {
}

/* Tablet */
@media (min-width: 576px) and (max-width: 768px) {
}

/* Phone */
@media (max-width: 576px) {
  h2.head {
    font-size: 14px;
    padding-bottom: 5px;
  }

  ul.location-cities {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }

  ul.location-rooms {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }

  ol.room-details{
    grid-template-columns: repeat(1,1fr);
    gap: 10px;
  }
}

/*#endregion */
