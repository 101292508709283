div.cardbank {
	height: 240px;
	display: grid;
	grid-template-rows: auto max-content;
	gap: 5px;
}

div.cardbank > div.info {
	border:solid 2px #04a951;
    background-color: rgba(255, 255, 255, 0.434);
	border-radius: 10px;
    display: grid;
    gap: 5px;
}

div.cardbank > div.info > img {
    object-fit: contain;
    height: 64px;
    display: block;
    margin: 10px;
}
div.cardbank > div.info > strong {
    direction: ltr;
    text-align: center;
    color: #00f;
    font-weight: normal;
    font-size: 24px;
}
div.cardbank > div.info > span {
    text-align: center;
    color: #000;
    font-weight: normal;
    font-size: 18px;
}