article.levels {
  display: block;
  box-shadow: #888 1px 1px 5px;
  border-radius: 5px;
  width: 100%;
  margin: 10px auto;
  padding: 5px;
  background: rgba(0, 255, 0, 0.01);
}

article.levels > h2 {
  font-size: 18px;
  border-bottom: solid 1px #8888887e;
  padding: 10px;
  margin: 0;
  text-align: center;
  color: #888;
}

article.levels > h3 {
  margin: 0;
  padding: 10px 15px;
}

article.levels > div {
  padding: 10px;
  display: grid;
}

article.levels > div > p {
  font-size: 13px;
  padding: 0 15px;
  margin: 0;
}

article.levels > div > strong {
  font-size: 16px;
  padding: 10px;
  margin: 0;
  display: inline-block;
  margin: 10px auto;
  text-align: center;
  border: solid 1px #888;
  border-radius: 8px;
  box-shadow: #6affff 1px 1px 5px;
  background-color: #0300b3;
  color: #fff;
}

article.levels > div > b {
    font-size: 15px;
    color: #fc7a00;
}

article.levels > div > ul {
    margin: 10px 0;
}

article.levels > div > ul > li {
    font-size: 14px;
}

/*#region responsive */

/* Monitor */
@media (min-width: 768px) and (max-width: 992px) {
}

/* Tablet */
@media (min-width: 576px) and (max-width: 768px) {
}

/* Phone */
@media (max-width: 576px) {
    article.levels > h3 {
        margin: 10px 0 0 0;
        padding: 0;
        text-align: center;
        font-size: 16px;
      }

    article.levels > div > p {
        font-size: 12px;
        padding: 0;
        text-align: justify;
        margin: 10px 0;
      }

      article.levels > div > strong {
        font-size: 14px;
      }
      
      article.levels > div > b {
          font-size: 12px;
          text-align: center;
      }
      
      article.levels > div > ul {
          margin: 10px 5px;
          padding: 0;
      }
      
      article.levels > div > ul > li {
          font-size: 13px;
          text-align: justify;
          list-style-type: square;
      }
}

/*#endregion */
