article.register {
    display: block;
    box-shadow: #888 1px 1px 5px;
    border-radius: 5px;
    width: 100%;
    max-width: 700px;
    margin: 50px auto;
    padding: 15px;
    background: rgba(0, 255, 0, .01);
}

article.register>div.header {
    font-size: 18px;
    font-weight: bolder;
    border-bottom: solid 1px rgba(0, 0, 0, .1);
    padding: 10px;
    margin-bottom: 10px;
    text-align: center;
}

article.register>div.form>div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}


article.register div>label {
    width: 100%;
    display: block;
    padding: 5px 5px;
    color: #888;
    font-size: 11px;
}

article.register div>button {
    width: 100%;
    margin: 5px 0;
}

article.register div>input  {
    width: 100%;
    display: block;
    border: solid 1px #888;
}

article.register div > .p-dropdown{
    border: solid 1px #888;
    
}

article.register div>input[required],#crmStateId,#crmCityId{
    border:solid   1px red;
    border-width: 1px 4px 1px 1px;
}

article.register div>input.ltr {
    direction: ltr;
}

.p-calendar {
    width: 100%;
}

.p-togglebutton {
    width: 100%;
}

.picker-input-wrapper{
    height: auto;
    font-family: auto;
}

.rmdp-container  {
width: 100%;
height: 35px;
display: block;
}

.rmdp-container  button {
width:auto !important;
}

.rmdp-container > input {
    font-size: 16px;
    font-family: IranSans;
    width: 100%;
    padding: 10px;
    height: 50px;
}

/*#region responsive */

/* Monitor */
@media (min-width: 768px) and (max-width: 992px) {}

/* Tablet */
@media (min-width: 576px) and (max-width: 768px) {}

/* Phone */
@media (max-width: 576px) {
    article.register {
        width: 100%;
    }

    article.register>div.form>div {
        grid-template-columns: 1fr;
    }

}

/*#endregion */