/*#region article.call-back */
article.call-back {
    text-align: center;
    display: block;
    box-shadow: #888 1px 1px 5px;
    border-radius: 5px;
    width: 50%;
    margin: 50px auto;
    padding: 15px;
    background: rgba(0,255,0,.01);
}

article.call-back > div.header{
    font-size: 20px;
    font-weight: bolder;
    border-bottom: solid 1px rgba(0,0,0,.1);
    padding: 10px;
}

article.call-back h2 {
    font-size: 16px;
}

article.call-back p {
    color: #888;
    font-size: 14px;
}


@-webkit-keyframes checkmark {
    0% {
        stroke-dashoffset: 50px
    }

    100% {
        stroke-dashoffset: 0
    }
}

@-ms-keyframes checkmark {
    0% {
        stroke-dashoffset: 50px
    }

    100% {
        stroke-dashoffset: 0
    }
}

@keyframes checkmark {
    0% {
        stroke-dashoffset: 50px
    }

    100% {
        stroke-dashoffset: 0
    }
}

@-webkit-keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 240px
    }

    100% {
        stroke-dashoffset: 480px
    }
}

@-ms-keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 240px
    }

    100% {
        stroke-dashoffset: 480px
    }
}

@keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 240px
    }

    100% {
        stroke-dashoffset: 480px
    }
}

article.call-back svg {
    display: block;
    width: auto;
    margin: 30px auto;
    text-align: center;
    transform: scale(1.5);
}


article.call-back svg path {
    -webkit-animation: checkmark 0.55s ease-in-out 1.0s backwards;
    animation: checkmark 0.55s ease-in-out 1.0s backwards
}

article.call-back svg circle {
    -webkit-animation: checkmark-circle 1.0s ease-in-out backwards;
    animation: checkmark-circle 1.0s ease-in-out backwards
}

/*#endregion */



/*#region responsive */

/* Monitor */
@media (min-width: 768px) and (max-width: 992px) {}

/* Tablet */
@media (min-width: 576px) and (max-width: 768px) {}

/* Phone */
@media (max-width: 576px) {
    article.call-back{
        width: 100%;
        margin: 10px 0;
    }
}

/*#endregion */
