/* #region article.add-new-queue */
article.add-new-queue {
	margin: 0;
}

article.add-new-queue h2 {
	font-size: 16px;
	border-bottom: solid 1px #0b36f560;
	padding: 10px 0;
	display: grid;
	grid-template-columns: max-content max-content;
	justify-content: space-between;
}

article.add-new-queue h2 > button.prev {
	border: solid 0px transparent;
	padding: 5px;
	font-family: "IRANSans-web";
	direction: ltr;
	display: grid;
	grid-template-columns: auto auto;
	align-items: center;
	color: red;
}

article.add-new-queue div.buttons {
	margin: 10px 0;
}

/* #endregion */

/* #region ul.locations */
ul.locations {
	padding: 0;
	margin: 0;
	list-style-type: none;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 15px;
}

ul.locations > li {
	border: solid 1px #888;
	border-radius: 7px;
	display: grid;
	grid-template-rows: auto max-content;
	overflow: hidden;
	background: #fff;
	gap: 0px;
}

ul.locations > li > a {
	cursor: pointer;
	position: relative;
}

ul.locations > li > a > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

ul.locations > li > a > h3 {
	padding: 5px;
	margin: 0;
	background: rgba(0, 0, 0, 0.414);
	text-align: center;
	border-radius: 7px 7px 0 0;
	color: #fff;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
}

ul.locations > li > div > label::after {
	content: " : ";
}

ul.locations > li > div {
	padding: 5px;
	background: #000;
	color: #fff;
}

/* #endregion */

/* #region ul.rooms */
ul.rooms {
	padding: 0;
	margin: 0;
	list-style-type: none;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 15px;
}

ul.rooms > li {
	border-radius: 7px;
	overflow: hidden;
	background: #fff;
}

ul.rooms > li > a {
	border: solid 1px #888;
	display: block;
	position: relative;
	overflow: hidden;
	box-shadow: #888 1px 1px 5px;
}

ul.rooms > li > a > img {
	width: 100%;
	height: 100%;
	display: block;
	object-fit: cover;
	transition: all ease-in 0.5s 0.1s;
}

ul.rooms > li > a:hover > img {
	transform: scale(1.1);
}

ul.rooms > li > a > span {
	text-align: center;
	background: #00000099;
	padding: 10px;
	color: #fff;
	position: absolute;
	bottom: 0;
	width: 100%;
}

/* #endregion */

/* #region ul.details */
ol.details {
	list-style-type: none;
	display: grid;
	gap: 15px;
	margin: 0;
	padding: 10px 0;
}
ol.details > li {
	border-right: solid 5px #1e0ad0;
	border-bottom: dashed 1px #1e0ad099;
	display: grid;
	padding: 10px;
}

ol.details > li > label {
	color: #888;
}

ol.details > li > span {
	font-weight: bold;
}

/* #endregion */

/* #region table.calendar */
table.calendar {
	width: 100%;
	border-spacing: 0cap;
}

table.calendar tr > th,
table.calendar tr > td {
	border: solid 1px #888;
}

table.calendar tr > th {
	background: #1d00f8ad;
	color: #fff;
	padding: 5px;
}

table.calendar tr > td {
	text-align: center;
	height: 60px;
}

table.calendar tr > td > a {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding: 5px;
	cursor: pointer;
	transition: all ease-in 0.1s 0.3s;
}

table.calendar tr > td > a.active {
	background: #0b36f599;
}

table.calendar tr > td > a.hover {
	background: #0b36f54e;
}

table.calendar tr > td > a:hover {
	background-color: #4d65ff85;
}

/* #endregion */

/* #region table.families */
table.families {
	width: 100%;
	border-spacing: 0cap;
}

table.families tr > th,
table.families tr > td {
	border: solid 1px #888;
}

table.families tr > th {
	background: #1d00f8ad;
	color: #fff;
	padding: 5px;
}

table.families tr > td {
	text-align: center;
}

table.families tr > td > input {
	padding: 5px 10px;
	width: 100%;
	border: solid 0px #888;
	font-family: "IRANSans-web";
	text-align: center;
}

table.families tr > td > select {
	padding: 5px 10px;
	width: 100%;
	border: solid 0px #888;
	font-family: "IRANSans-web";
	text-align: center;
}

table.families tr > td > button {
	font-size: 12px;
	padding: 5px 10px;
	text-align: center;
}

/* #endregion */

/* #region div.services */
div.services {
	display: grid;
	gap: 15px;
}

div.services > table {
	width: 100%;
	border-spacing: 0px;
}

div.services > table th {
	border: solid 1px #888;
	background: #1d00f8ad;
	color: #fff;
	padding: 5px;
}

div.services > table td {
	border: solid 1px #6a6a6a75;
	padding: 0 10px;
}

div.services > table td:nth-child(1),
div.services > table td:nth-child(6) {
	text-align: center;
}

div.services > table td:nth-child(5),
div.services > table td:nth-child(7) {
	text-align: left;
	padding: 0 10px;
}

div.services > table td > div > input {
	width: 80px;
	direction: ltr;
	text-align: center;
	border: solid 0px #888;
	justify-self: center;
	border-radius: 5px;
	text-align: center;
	padding: 5px;
  background-color: transparent;
}

div.services > table td > select {
	width: 100%;
	padding: 5px;
	border: solid 1px transparent;
	font-family: "IranSans-web";
  background-color: transparent;
}

div.services > table td > div.group {
	display: grid;
	grid-template-columns: max-content auto max-content;
	align-items: center;
	text-align: center;
}

div.services > table td > div.group a  {
	cursor: pointer;
}

div.services > table td > div.group a:first-child {
	color: red;
}
div.services > table td > div.group a:last-child {
	color: green;
}

div.services > table tr.active > td {
  background-color: #2fff0050;
}

/* #endregion */

/* #region div.properties */
div.properties {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 15px;
	gap: 10px;
}

div.properties > div {
	border: solid 1px #888;
	border-radius: 5px;
	display: grid;
	gap: 10px;
	padding: 5px;
}

div.properties > div > label {
	color: #888;
}

div.properties > div > input {
	padding: 5px;
	border: solid 1px #888;
}

/* #endregion */

/* #region table.summary */
table.summary {
	width: 100%;
	border-spacing: 0px;
	margin-bottom: 15px;
}

table.summary th {
	border: solid 1px #888;
	background: #1d00f8ad;
	color: #fff;
	padding: 5px;
}

table.summary td {
	border: solid 1px #888;
	padding: 0 10px;
}

table.summary td:nth-child(1),
table.summary td:nth-child(4) {
	text-align: center;
}

table.summary td:nth-child(3),
table.summary td:nth-child(5) {
	text-align: left;
}

/* #endregion */

/* #region div.date-duration */
div.date-duration {
	display: grid;
	align-items: center;
	grid-template-columns: auto auto;
	align-items: center;
	align-content: space-between;
	margin: 0 auto;
	width: 400px;
	gap: 10px;
}

div.date-duration > div {
	display: grid;
	margin: 0 auto;
	align-items: center;
}

div.date-duration > div > label {
	display: inline-block;
	font-size: 14px;
	color: #888;
	padding: 10px 0;
}

div.date-duration > div > span {
	font-size: 12px;
	color: #000;
}

div.date-duration > div input {
	padding: 10px;
	width: 150px;
	border: solid 1px #888;
	border-radius: 5px;
	height: auto;
	text-align: center;
	font-family: "IRANSans-web";
}

/* #region */

/*#region responsive */

/* Monitor */
@media (min-width: 768px) and (max-width: 992px) {
}

/* Tablet */
@media (min-width: 576px) and (max-width: 768px) {
}

/* Phone */
@media (max-width: 576px) {
	article.add-new-queue h2 {
		font-size: 14px;
		padding: 5px 0;
	}

	ul.locations {
		grid-template-columns: repeat(1, 1fr);
		gap: 10px;
	}

	ul.rooms {
		grid-template-columns: repeat(1, 1fr);
		gap: 10px;
	}

	ol.room-details {
		grid-template-columns: repeat(1, 1fr);
		gap: 10px;
	}

	table.calendar th {
		font-size: 11px;
	}

	div.services {
		grid-template-columns: 1fr;
		gap: 10px;
	}

	div.properties > div {
		width: 100%;
	}

	table.summary th,
	table.summary td {
		font-size: 12px;
	}
}

/*#endregion */
