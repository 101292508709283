/* #region div.store-details */
div.store-details {
  padding: 5px;
}

div.store-details > h2 {
  text-align: center;
  border-bottom: solid 1px #888;
  font-size: 14px;
  padding: 10px;
}

div.store-details > strong {
  font-size: 12px;
}

div.store-details > p {
  font-size: 12px;
  color: #888;
}

div.store-details ul{
  display: grid;
  list-style-type: none;
  padding: 0;
  margin: 0;
  gap: 10px;
}

div.store-details ul > li{
  border-right: solid 3px #888;
  font-size: 14px;
}

div.store-details ul > li > label{
  background: #88888875;
  padding: 0 10px;

}
div.store-details ul > li > label::after{
  content: " : ";
}
div.store-details ul > li > span{
  background: #88888860;
  padding: 0 5px;
}

div.store-details ol{
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
  text-align: center;
}

div.store-details ol > li{
  display: inline-block;
}

div.store-details ol > li > a{
  padding: 10px;
}

div.store-details ol > li > a> i{
font-size: 25px;
}


/* #endregion */

/*#region responsive */

/* Monitor */
@media (min-width: 768px) and (max-width: 992px) {
}

/* Tablet */
@media (min-width: 576px) and (max-width: 768px) {
}

/* Phone */
@media (max-width: 576px) {
}

/*#endregion */
