@font-face {
  font-family: "IRANSans-web";
  src: url(/public/fonts/IRANSans-web.eot);
  src: url(/public/fonts/IRANSans-web.eot?#iefix) format("embedded-opentype"),
    url(/public/fonts/IRANSans-web.woff) format("woff"),
    url(/public/fonts/IRANSans-web.woff2) format("woff2"),
    url(/public/fonts/IRANSans-web.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --font-family: "IRANSans-web" !important;
}

html {
  font-size: 1rem;
  direction: rtl;
}

a{
  text-decoration: none;
}

a:visited{
  color: inherit;
  text-decoration: none;;
}

.p-icon.p-submenu-icon {
  transform: rotate(180deg);
}

body {
  margin: 0px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--surface-ground);
  font-family: var(--font-family);
  font-weight: normal;
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div.head {
  height: 30px;
  background: #57606f;
  display: block;
  width: 100%;
}

.p-float-label {
  margin: 10px 0;
}

.p-float-label > label {
  left: auto;
  right: 0.75rem;
  color: #888;
}

.p-dropdown-filter-icon {
  right: auto;
  left: 0.75rem;
}

.p-dropdown-filter {
  padding-right: auto;
  margin-right: auto;
  padding-left: 1.75rem;
  margin-left: -1.75rem;
}

