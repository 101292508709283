/* #region div.top-banner*/
div.top-banner {
	display: grid;
	grid-template-columns: max-content auto max-content;
	background: #fff;
	height: 80px;
	box-shadow: #fff 1px 1px 5px;
}

div.top-banner > img {
	height: 80px;
}

div.top-banner > div > h2 {
	padding: 5px;
	margin: 0;
	font-size: 18px;
}

div.top-banner > div > p {
	font-size: 12px;
}

/* #endregion */

/* #region div.profile */
div.menu-bar {
	position: relative;
}

div.profile {
	position: absolute;
	left: 40px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 999;
}

div.profile > div {
	display: grid;
	grid-template-columns: 32px auto auto;
	gap: 10px;
	direction: ltr;
	align-items: center;
	background: #fff;
	padding: 5px;
	border-radius: 5px;
	box-shadow: #00000022 1px 1px 5px;
	cursor: pointer;
}

div.profile > div > a {
	font-size: 25px;
}

div.profile > div > div {
	display: grid;
	grid-template-rows: auto auto;
}

/* #endregion */

/* #region div.back */

div.back {
	position: absolute;
	left: 10px;
	top: 50%;
	transform: translateY(-50%);
	align-items: center;
	background: #fff;
	padding: 5px;
	border-radius: 5px;
	box-shadow: #00000022 1px 1px 5px;
	cursor: pointer;
}

div.back > a {
	font-size: 25px;
}

/* #endregion */

/* #region ol.profile-menu*/
ol.profile-menu {
	position: absolute;
	left: 0px;
	top: calc(100% + 5px);
	list-style-type: none;
	background: #fff;
	box-shadow: #000000a6 1px 1px 5px;
	font-size: 12px;
	padding: 0;
	margin: 0;
	display: none;
	direction: rtl;
	z-index: 999;
	overflow: hidden;
}

div.profile > ol.show {
	display: block;
}

ol.profile-menu > li {
	border-bottom: solid 1px #00000051;
}

ol.profile-menu > li > a {
	padding: 5px 10px;
	display: block;
	text-align: right;
	cursor: pointer;
	display: grid;
	grid-template-columns: 24px max-content;
	transition: all eas 0.2s 0.1s;
	text-decoration: none;
}

ol.profile-menu > li:hover > a {
	background: #00000030;
}

/* #endregion */

/* #region article.main*/
article.main {
	margin: 10px auto;
	width: 95%;
}

article.main > header {
	background: #f5f5f2;
	text-align: center;
	color: #000;
	padding: 0;
	font-size: 14px;
	border-bottom: solid 1px rgba(0, 255, 255, 0.26);
	margin-bottom: 10px;
}

article.main > div.content {
	display: inline-block;
	width: 100%;
}

article.main > footer {
	background: #282828;
	color: #fff;
	padding: 15px;
	font-size: 14px;
	width: 100%;
}

article.main > footer > div.links {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
}

article.main > footer > p {
	text-align: center;
	font-size: 11px;
}

ul.main-menu {
	padding: 10px 0;
	margin: 0;
}

ul.main-menu > li {
	display: inline-block;
	margin: 0 10px;
}

ul.main-menu > li > a {
	display: block;
	padding: 10px 5px;
	text-decoration: none;
	color: #000;
	font-size: 18px;
	transition: all ease-in 0.4s 0.1s;
}

ul.main-menu > li > a:hover {
	color: #ffae00;
}
/* #endregion */

/*#region responsive */

/* Monitor */
@media (min-width: 768px) and (max-width: 992px) {
	article.main > footer > div.links {
		grid-template-columns: repeat(2, 1fr);
	}
}

/* Tablet */
@media (min-width: 576px) and (max-width: 768px) {
	article.main > footer > div.links {
		grid-template-columns: repeat(2, 1fr);
	}
}

/* Phone */
@media (max-width: 576px) {
	ul.main-menu {
		padding: 0;
		margin: 0;
	}
	ul.main-menu > li {
		margin: 0 2px;
	}
	ul.main-menu > li > a {
		font-size: 11px;
	}
	article.main > footer > div.links {
		font-size: 11px;
		padding: 15px 0;
		grid-template-columns: repeat(1, 1fr);
	}

	div.top-banner {
		height: 160px;
		position: relative;
		justify-content: space-between;
		grid-template-columns: auto auto;
	}

	div.top-banner > div {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		border-top: solid 1px #8888885a;
	}

	div.top-banner > div > h2 {
		font-size: 16px;
		padding: 0;
	}

	div.top-banner > div > p {
		font-size: 10px;
	}

  div.menu-bar {
    display: grid;
  }
  
  div.back {
    position: fixed;
    transform: translateY(0);
    left: 10px;
    top:10px;
    z-index: 999;
    background-color: #000;
    color: #fff;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    line-height: 150%;
  }

	div.profile {
		position: relative;
		left: 0;
	}
}

/*#endregion */
